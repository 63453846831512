import React from 'react';
import Card from 'react-bootstrap/Card';
import { useTranslation } from '../../LanguageContext.js';

function AboutCard() {
  const { t } = useTranslation();

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <br />
          <br />
          <span className="purple">{t('aboutMeTitle')}</span>
          <br />
          {t('aboutMeText')}
          <br />
          <br />
          <span className="purple">{t('passionsTitle')}</span>
          <br></br>
          {t('passionsText')}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
