import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Particle from '../Particle';
import Techstack from './Techstack';
import AboutCard from './AboutCard';
import Tilt from 'react-parallax-tilt';
import MyPhoto from '../../Assets/nowe_foto_1.png';
import { useTranslation } from '../../LanguageContext';

function About() {
  const { t } = useTranslation();

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: 'center', padding: '10px' }}>
          <Col
            md={7}
            style={{
              justifyContent: 'center',
              paddingTop: '30px',
              paddingBottom: '50px',
            }}
          >
            <h1 style={{ fontSize: '2.1em', paddingBottom: '20px' }}>
              {t('aboutTitle')}
            </h1>
            <h2>
              {t('aboutSubtitle')}
              <strong className="purple"> {t('aboutDegree')}</strong>{' '}
              {t('aboutDegreeInfo')}
            </h2>
            <AboutCard />
          </Col>
          <Col
            md={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              paddingTop: '30px',
              paddingBottom: '30px',
            }}
            className="about-img circular-image"
          >
            <Tilt>
              <img
                src={MyPhoto}
                alt="about"
                className="img-fluid rounded-circle"
              />
            </Tilt>
          </Col>
        </Row>
        <h1 className="project-heading">
          <strong className="purple">{t('skillsetTitle')}</strong>
        </h1>

        <Techstack />
      </Container>
    </Container>
  );
}

export default About;
