import React, { useState, useRef } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineFile,
  AiOutlineCode,
  AiOutlineMail,
} from 'react-icons/ai';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from '../LanguageContext.js';

function NavBar() {
  const [expand, setExpand] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const { t, language, setLanguage, currentFlag } = useTranslation();
  const dropdownRef = useRef(null);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setLanguageDropdownOpen(false); // Zamknij dropdown po zmianie języka
  };

  const scrollHandler = () => {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  };

  window.addEventListener('scroll', scrollHandler);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const handleDropdownClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setLanguageDropdownOpen(false);
    }
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const handleDropdownLanguageClick = (lang) => {
    changeLanguage(lang);
  };

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? 'sticky' : 'navbar'}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex">
          Hubert Przypis
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={toggleExpand}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => setExpand(false)}>
                <AiOutlineHome style={{ marginBottom: '2px' }} /> {t('home')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/about" onClick={() => setExpand(false)}>
                <AiOutlineUser style={{ marginBottom: '2px' }} /> {t('about')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/resume" onClick={() => setExpand(false)}>
                <AiOutlineFile style={{ marginBottom: '2px' }} /> {t('resume')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/projects"
                onClick={() => setExpand(false)}
              >
                <AiOutlineCode style={{ marginBottom: '2px' }} />{' '}
                {t('projects')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => setExpand(false)}
              >
                <AiOutlineMail style={{ marginBottom: '2px' }} /> {t('contact')}
              </Nav.Link>
            </Nav.Item>

            {/* Dropdown zmiany języka */}
            <div ref={dropdownRef} onClick={handleDropdownClick}>
              <Dropdown
                align="end"
                show={languageDropdownOpen}
                onToggle={toggleLanguageDropdown}
                style={{ position: 'relative' }}
              >
                <Dropdown.Toggle
                  id="dropdown-language"
                  variant="link"
                  onClick={toggleLanguageDropdown}
                  style={{ cursor: 'pointer' }}
                >
                  {currentFlag && (
                    <img
                      src={currentFlag}
                      alt={language && language.toUpperCase()}
                      width="40"
                      height="40"
                      style={{ borderRadius: '50%', textAlign: 'center' }}
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleDropdownLanguageClick('en')}
                  >
                    <img
                      src="/flags/usa.png"
                      alt="English flag"
                      width="30"
                      height="30"
                      style={{ borderRadius: '50%', textAlign: 'center' }}
                    />{' '}
                    {t('English')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleDropdownLanguageClick('pl')}
                  >
                    <img
                      src="/flags/poland.png"
                      alt="Polish flag"
                      width="30"
                      height="30"
                      style={{ borderRadius: '50%', textAlign: 'center' }}
                    />{' '}
                    {t('Polish')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
