import React from 'react';
import Typewriter from 'typewriter-effect';
import { useTranslation } from '../../LanguageContext';

function Type() {
  const { t } = useTranslation();

  return (
    <Typewriter
      options={{
        strings: [
          t('cyberSecurity'),
          t('webDevelopment'),
          t('softwareDevelopment'),
          t('itSpecialist'),
          t('marketingAds'),
          t('seo'),
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
