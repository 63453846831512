import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from '../../LanguageContext';
import { Link } from 'react-router-dom';
import '../../style.css';
import './OnlineProjects.css'; // Import the CSS file

// Import static images
import project1Img from '../online/Images/lock.png';
import project2Img from '../online/Images/keyboard.png';
import project3Img from '../online/Images/hangman.png';

function OnlineProjects() {
  const { t } = useTranslation();

  const projectsData = [
    {
      title: 'Password Generator',
      descriptionKey: 'OnlineDescription1',
      image: project1Img, 
      link: `${process.env.PUBLIC_URL}/Projekty/GeneratorHasel/index.html`,
    },
    {
      title: 'TypingExercise',
      descriptionKey: 'OnlineDescription2',
      image: project2Img, 
      link: `${process.env.PUBLIC_URL}/Projekty/TypingExcercise/index.html`, 
    },
    {
      title: 'Hangman',
      descriptionKey: 'OnlineDescription3',
      image: project3Img, 
      link: `${process.env.PUBLIC_URL}/Projekty/Hangman/index.html`, 
    },
  ];

  return (
    <Container fluid className="projects-section">
      <Container>
        <Row>
          <Col className="github-tile">
            <Card>
              <Card.Body>
                <Card.Title>{t('OnlineProjectsTitle')}</Card.Title>
                <Button
                  variant="primary"
                  href="https://github.com/HuBiT55"
                  target="_blank"
                  className="mr-2"
                >
                  {t('githubButton')}
                </Button>
                <Link to="/Projects">
                  <Button variant="info">
                    {t('BackButton')}
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="projects-container">
          {projectsData.map((project, index) => (
            <Card
              key={index}
              className="project-card card"
              onClick={() => window.open(project.link, '_blank')}
            >
              <div className="image-wrapper">
                <img
                  src={project.image}
                  alt={project.title}
                  className="project-image"
                />
                <div className="overlay"></div>
              </div>
              <Card.Body>
                <Card.Title>{project.title}</Card.Title>
                <Card.Text>{t(project.descriptionKey)}</Card.Text>
                {project.link && (
                  <Button variant="info" onClick={() => window.open(project.link, '_blank')}>
                    {t('browserPlay')}
                  </Button>
                )}
              </Card.Body>
            </Card>
          ))}
        </div>
      </Container>
    </Container>
  );
}

export default OnlineProjects;
